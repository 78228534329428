<template>
    <div>
        <div class="transaction-notification">
            <a :href="link" target="_blank" class="link-wrap">
                <div class="transaction-status" :class="'status-' +status">
                    <span>{{ status }}</span> <span class="link-icon"></span>
                </div>
                <div class="transaction-desc">{{ rec.desc }}</div>
                <div class="transaction-date">{{ new Date(rec.date).toLocaleString('en-GB', { timeZone: 'UTC' }) }}</div>
            </a>
            <div class="transaction-close" @click="close"></div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { computed, onMounted, ref } from "vue";
    import { ConfigInstance } from "~/composables/Config";
    import { PearlReader } from "~/composables/PearlReader";
    import { modifyHistoryHashStatus } from "~/composables/utils/utils";
    import { useMainStore } from "~/stores/mainStore";
    import type { HistoryTransaction } from "~/composables/utils/utils";

    const emit = defineEmits(["close"]);

    const props = defineProps<{ rec: HistoryTransaction }>();
    const status = ref(props.rec.status);
    const blocks = ref(0);

    onMounted(async () => {
        if (props.rec.status === "Processing") {
            const reader = new PearlReader(ConfigInstance.blockchain);
            const lastBlock = await reader.getLastBlock();
            const res = await reader.getTransactionInfo(props.rec.hash);
            const res2 = await reader.getTransactionReceipt(props.rec.hash);
            console.log(res, res2);
            const confirms = res && res.blockNumber ? lastBlock - res.blockNumber : -1;

            // res.blockNumber - Block number where this transaction was in. null if pending
            // res2.status - Boolean: TRUE if the transaction was successful, FALSE if the EVM reverted the transaction.
            let status = "Processing";
            if (res2 !== null && res2.status) {
                if (confirms > 0) {
                    if (confirms >= ConfigInstance.blockchain.minConfirmationsCount) {
                        status = "Done";
                    }
                }
            } else {
                if (confirms > 0 && confirms >= ConfigInstance.blockchain.minConfirmationsCount) {
                    status = "Failed";
                }
            }

            if (status !== props.rec.status) {
                // update status in local storage
                modifyHistoryHashStatus(props.rec.hash, status);
                const store = useMainStore();
                store.updateVersion();
            }
        }
    });

    function close() {
        emit("close");
    }

    const link = computed(() => {
        return ConfigInstance.blockchain.blockScanUrl + "/tx/" + props.rec.hash;
    });
</script>

<style scoped lang="scss">
    .transaction-notification {
        display: flex;
        gap: 24px;
        margin: 4px 0;
        background: var(--dark-1);
        height: 44px;
        max-width: 710px;
        border-radius: 42px;
        border: 1px solid var(--dark-4);
        box-sizing: border-box;
        align-items: center;
        font-size: 20px;
    }

    .link-wrap {
        display: flex;
        align-items: center;
        gap: 24px;
    }

    .transaction-date {
        width: 200px;
        overflow: hidden;
    }

    .transaction-desc {
        min-width: 175px;
        overflow: hidden;
    }
    .transaction-status {
        width: 170px;
        overflow: hidden;
        display: flex;
        padding: 2px 20px;
        justify-content: center;
        align-items: center;
        border-radius: 40px;
        color: var(--dark-1);
        margin-left: 4px;
        box-sizing: border-box;
    }

    .status-Done {
        background-color: var(--confirm-color);
    }

    .status-Processing {
        background-color: var(--processing-color);
    }

    .status-Failed {
        background-color: var(--processing-color);
    }

    .transaction-close {
        margin-left: auto;
        cursor: pointer;
        margin-right: 14px;
        background-image: url("/img/icons/bone_close.svg");
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50%;
        transition: 200ms ease opacity;
        min-width: 20px;

        &:hover {
            opacity: 0.6;
        }
    }

    .link-icon {
        width: 20px;
        height: 20px;
        display: inline-flex;
        background: url("/img/icons/link-dark.png") center center no-repeat;
        background-size: contain;
        margin-left: 8px;
    }

    @media screen and (max-width: 767px) {
        .transaction-notification {
            font-size: 14px;
        }

        .transaction-desc {
            width: 110px;
            min-width: 110px;
        }

        .transaction-date {
            width: 128px;
        }

        .transaction-status {
            width: 140px;
        }

        .link-wrap {
            gap: 16px;
        }
    }


    @media screen and (max-width: 479px) {

        .transaction-notification {
            font-size: 12px;
        }

        .transaction-desc {
            width: auto;
            min-width: auto;
            line-height: 18px;
        }

        .transaction-date {
            width: auto;
            line-height: 18px;
        }

        .transaction-status {
            width: 100px;
        }

        .link-wrap {
            gap: 12px;
        }
    }
</style>
<template>
    <div class="notifications-wrapper">
        <template v-for="(rec, index) in notifications">
            <TransactionNotification
                v-if="rec.show"
                :rec="rec"
                :key="rec.hash"
                @close="close(index)"
            />
        </template>
    </div>
</template>

<script setup lang="ts">
    import { ref, onMounted  } from "vue";
    import TransactionNotification from "~/components/TransactionNotification.vue";
    import type { HistoryTransaction } from "~/composables/utils/utils";
    import { flagAsSeenHistoryHash, getHashHistory } from "~/composables/utils/utils";
    import { useMainStore } from "~/stores/mainStore";

    const notifications = ref<HistoryTransaction[]>([]);

    onMounted(() => {
        notifications.value = getHashHistory();
    });

    function close(index: number) {
        flagAsSeenHistoryHash(notifications.value[index].hash);
        const store = useMainStore();
        store.updateVersion();
    }
</script>

<style scoped>
    .notifications-wrapper {
        position: fixed;
        margin: 20px;
        bottom: 20px;
        right: 0;
        z-index: 100;
        max-height: 520px;
        overflow: hidden;
    }

    @media screen and (max-width: 479px) {
        .notifications-wrapper {
            margin: 8px;
            width: calc(100% - 16px);
        }
    }
</style>
<template>
    <header>
        <div class="main-header-container" :class="{'show-mobile': showMobileMenu}" >

            <div class="header-wrapper">

                <!-- <div class="mobile-header">
                    {{ pageName($route.name?.toString() ?? "") }}
                </div> -->

                <a href="/" class="header-logo-link w-nav-brand" aria-label="home">
                    <img src="/img/logo.svg" alt="" class="header-logo pd-logo">
                </a>

                <ul class="header-ul header-links">
                    <li>
                        <NuxtLink to="/story">Story</NuxtLink>
                    </li>
                    <li>
                        <NuxtLink to="/">Marketplace</NuxtLink>
                    </li>
                    <li>
                        <NuxtLink to="/dashboard">Dashboard</NuxtLink>
                    </li>
                    <!-- <li>
                        <NuxtLink to="/leaderboard">Leaderboard</NuxtLink>
                    </li> -->
                </ul>
                <ul class="header-ul login-wrapper">
                    <li v-loading="loading">
                        <span class="custom-btn-as-link btn-primary" v-if="loading">Loading...</span>
                        <template v-else-if="!address">
                            <span class="custom-btn-as-link btn-primary" @click="showLoginSelect = true">
                                Login
                                <span class="line-rounded-icon link-icon-right" style="transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg); transform-style: preserve-3d;"></span>
                            </span>
                        </template>
                        <template v-else>
                            <div class="login-menu-wrapper menu-hide-on-mobile">
                                <div class="address logged-in-wrapper w-inline-block">
                                    <span class="user-icon"></span><span>{{ adressFormat(address) }}</span>
                                    <div class="line-square-icon button-arrow logged-in"></div>
                                </div>
                                <div class="login-menu-dropdown">
                                    <div class="card user-popup">
                                        <div class="dropdown-wrapper">
                                            <div class="dropdown-item">
                                                <a :href="link" target="_blank" class="address-link">
                                                    <div class="line-square-icon menu-icon wallet-icon"></div> Public address
                                                </a>
                                            </div>
                                            <div class="dropdown-item">
                                                <img class="menu-icon" src="/img/icons/busd.svg" loading="lazy" alt="" width="24"> <div class="">{{ format(balance) }}</div>
                                            </div>
                                            <div class="dropdown-item">
                                                <div class="custom-btn logout-btn" @click="logout">
                                                    <div class="line-square-icon menu-icon"></div>
                                                    <span>Logout</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </li>
                </ul>

                <div
                    @click="showMobileMenu = !showMobileMenu"
                    class="hamburger-menu-wrapper w-nav-button"
                    style="-webkit-user-select: text;"
                    aria-label="menu"
                    role="button" tabindex="0"
                    aria-controls="w-nav-overlay-0"
                    aria-haspopup="menu"
                    aria-expanded="false"
                >
                    <div class="hamburger-menu-bar top"></div>
                    <div class="hamburger-menu-bar bottom"></div>
                </div>
            </div>
        </div>

        <div id="w-nav-overlay-0" class="w-nav-overlay" :class="{'show-mobile': showMobileMenu}" @click="showMobileMenu = false">
            <div class="header-nav-menu-wrapper">
                <ul class="header-ul">
                    <li>
                        <NuxtLink to="/story">Story</NuxtLink>
                    </li>
                    <li>
                        <NuxtLink to="/">Marketplace</NuxtLink>
                    </li>
                    <li>
                        <NuxtLink to="/dashboard">Dashboard</NuxtLink>
                    </li>
                    <!-- <li>
                        <NuxtLink to="/leaderboard">Leaderboard</NuxtLink>
                    </li> -->

                    <template v-if="address">
                        <li class="mobile-links first">
                            <span class="user-icon"></span><span>{{ adressFormat(address) }}</span>
                        </li>
                        <li class="mobile-links">
                            <a :href="link" target="_blank">
                                <div class="line-square-icon menu-icon wallet-icon"></div>
                                {{ addressText }}
                            </a>
                        </li>
                        <li class="mobile-links">
                            <div class="flex balance">
                                <img class="menu-icon" src="/img/icons/busd.svg" loading="lazy" alt="" width="24">
                                {{ format(balance) }}
                            </div>
                        </li>
                        <li @click="logout" class="mobile-links">
                            <a href="#" class="custom-btn logout-btn log-out">
                                <div class="line-square-icon menu-icon"></div>
                                <span>Logout</span>
                            </a>
                        </li>
                </template>
                    <template v-else>
                        <li class="mobile-links">
                            <button @click="showLoginSelect = true" class="btn-primary small header-btn-hidde-on-d no-wrap w-button">
                                Login
                                <span class="line-rounded-icon link-icon-right" style="transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg); transform-style: preserve-3d;"></span>
                            </button>
                        </li>
                    </template>
                </ul>
            </div>

        </div>

        <AppWalletUnlock v-if="showLoginSelect" @success="onSuccess" @close="closeWalletSelect" />
    </header>
</template>

<script lang="ts">
    import { defineComponent } from "vue";
    import { ConfigInstance } from "~/composables/Config";
    import { BigNumber } from "bignumber.js";
    import { Web3WalletProviderInstance } from "~/composables/Web3WalletProvider";
    import { mapState, mapActions } from "pinia";
    import type { ProviderAccount } from "~/stores/mainStore";
    import { useMainStore } from "~/stores/mainStore";
    import { PearlReader } from "~/composables/PearlReader";
    import { NuxtLink } from "#components";
    import AppWalletUnlock from "~/components/AppWalletUnlock.vue";

    export default defineComponent({
        components: {
            NuxtLink,
            AppWalletUnlock,
        },
        computed: {
            ...mapState(useMainStore, ["providerAccounts"]),
            address(): string | null {
                const mainAccount = this.providerAccounts[0] ?? null;
                return mainAccount?.address ?? null;
            },
            mainAccount(): ProviderAccount | null {
                return this.providerAccounts[0] ?? null;
            },
            link(): string {
                return ConfigInstance.blockchain.blockScanUrl + "/address/" + this.address;
            },
            isMobile(): boolean {
                if (window && window.innerWidth) {
                    return window.innerWidth < 900;
                } else {
                    return false;
                }
            }
        },
        data(): {
            loading: boolean;
            balance: string;
            showLoginSelect: boolean;
            addressText: string;
            showMobileMenu: boolean;
        } {
            return {
                loading: true,
                balance: "",
                showLoginSelect: false,
                addressText: "Public Address",
                showMobileMenu: false,
            };
        },
        async mounted() {
            this.loading = true;
            try {
                Web3WalletProviderInstance.setOnProviderAccountsChange(
                    (providerKind, addresses) => this.updateProviderAccounts(providerKind, addresses)
                );
                await Web3WalletProviderInstance.tryObtainExistingEthereumProvider();
            } finally {
                this.loading = false;
            }
        },
        watch: {
            async address(newValue, oldValue) {
                if (newValue) {
                    await this.updateBalance();
                }
            }
        },
        methods: {
            ...mapActions(useMainStore, ["updateProviderAccounts"]),
            async onSuccess() {
                if (this.address !== null) {
                    await this.updateBalance();
                }
                this.closeWalletSelect();
            },
            closeWalletSelect() {
                this.showLoginSelect = false;
            },
            logout(event: Event) {
                Web3WalletProviderInstance.clean();
                event.preventDefault();
            },
            async updateBalance(): Promise<void> {
                this.balance = "";
                if (!this.mainAccount) {
                    return;
                }
                this.loading = true;

                try {
                    const balance = await new PearlReader(ConfigInstance.blockchain).availableTokens(
                        this.mainAccount.address, ConfigInstance.blockchain.contracts.USDT
                    );
                    this.balance = new BigNumber(balance).toFixed(2);
                } finally {
                    this.loading = false;
                }
            },
            format(value: string | number) {
                if (!value || value === "...") {
                    return value;
                }
                return new BigNumber(value).toFormat(2);
            },
            adressFormat(address: string) {
                return address.substring(0, 2) + "..." + address.substring(address.length - 6);
            },
            pageName(name: string): string {
                switch (name) {
                    case "index": return "Main";
                    case "story": return "Story";
                    case "statistic": return "Statistic";
                    case "leaderboard": return "Leaderboard";
                    default: return name;
                }
            }
        },
    });
</script>

<style scoped lang="scss">

    .w-nav-overlay {
        width: 100%;
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        overflow: hidden;
        height: 100vh;
    }

    .header-nav-menu-wrapper {
        background-color: #052430;
        background-image: linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, 0) 25%);
        border-bottom: 1px solid #2f2f2f;
        padding: 8px 24px 32px;

        .header-ul {
            margin-top: 30px;
        }

        .header-ul > li > a {
            margin-left: 12px;
        }

        @media screen and (max-width: 479px) {
            .header-ul {
                flex-wrap: wrap;
            }

            .header-ul > li {
                width: 100%;
            }

            .header-ul > li > a {
                margin: 4px 0;
                width: 100%;
                padding: 12px 20px;
                font-size: 14px;
            }
        }
    }

    [data-nav-menu-open] {
        text-align: center;
        min-width: 200px;
        background: #c8c8c8;
        background-color: rgb(200, 200, 200);
        background-image: none;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        overflow: visible;
        display: block !important;
    }

    .show-mobile.w-nav-overlay {
        display: block;
    }

    .hamburger-menu-bar.top {
        transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg);
        transform-style: preserve-3d;
        transition: all ease 200ms;
    }

    .hamburger-menu-bar.bottom {
        transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg);
        transform-style: preserve-3d;
        transition: all ease 200ms;
    }

    .show-mobile {
        .hamburger-menu-bar.top {
            transform: translate3d(0px, 8px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(135deg) skew(0deg)
        }
        .hamburger-menu-bar.bottom {
            transform: translate3d(0px, -7px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(45deg) skew(0deg);
        }
    }

    .main-header-container {
        max-width: 1340px;
        padding-left: 24px;
        padding-right: 24px;
        width: 100%;
    }

    .w-nav-button {
        float: right;
        cursor: pointer;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        tap-highlight-color: rgba(0, 0, 0, 0);
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        padding: 18px;
        font-size: 24px;
        display: none;
        position: relative;
    }

    .hamburger-menu-bar {
        width: 52px;
        height: 4px;
        background-color: #052430;
        border-radius: 20px;
        margin-top: 12px;
        margin-bottom: 12px;
    }

    .hamburger-menu-wrapper {
        padding: 0;
        transition: transform .3s;
    }

    .hamburger-menu-wrapper:hover {
        transform: scale(1.1);
    }

    @media screen and (max-width: 991px) {
        .w-nav-button {
            display: block;
        }

        .hamburger-menu-bar {
            width: 32px;
            height: 3px;
            background-color: #fff;
        }

        .hamburger-menu-wrapper {
            margin-left: 24px;
        }
    }


    @media screen and (max-width: 767px) {
        .hamburger-menu-bar {
            width: 26px;
        }
    }

    .mobile-header {
        display: none;
        font-family: "Grenze", sans-serif;
        font-weight: 800;
        font-size: 32px;
    }

    .down-arrow {
        background: url("/img/Chevron.png");
        width: 20px;
        display: inline-flex;
        background-size: contain;
        height: 11px;
        margin-left: 12px;
    }

    .dropdown {
        box-sizing: border-box;
    }

    .dropdown-item {
        width: 100%;
        align-items: center;
        padding: 8px 24px;
        display: flex;

        @media screen and (max-width: 767px) {
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    .dropdown-item a {
        align-items: center;
        display: flex;
    }

    .header-ul > li {
        position: relative;
        font-size: 20px;
    }
    .header-ul > li:hover .dropdown {
        display: block;
    }

    .custom-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border: 1px solid transparent;
    }

    .custom-btn-as-link {
        margin-left: 32px;
        cursor: pointer;
        padding: 14px 24px;
    }


    .logout-btn {
        color: #ffbec2;
        cursor: pointer;

        &:hover {
            color: var(--canceled-color);
        }
    }

    .user-icon {
        background: url("/img/icons/pirate.svg") center center no-repeat;
        background-size: contain;
        height: 24px;
        width: 24px;
        display: inline-flex;
        margin-right: 8px;
    }

    .btns-wrapper {
        display: flex;
        justify-content: center;
    }

    .exit-button {
        float: right;
        color: var(--main-text-color);
        text-decoration: none;
        padding: 12px 38px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 200ms ease all;
        background: transparent;
        border-radius: 38px;
        border: 1px solid var(--dark-4);
    }

    header {
        height: 130px;
        background-color: var(--blue-1);
        background-image: linear-gradient(rgba(0, 0, 0, .2), rgba(0, 0, 0, 0));
        box-sizing: border-box;
        right:0;
        left:0;
        top: 0;
        display: flex;
        z-index: 10;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    .header-ul {
        display: flex;
        padding: 0;
        align-items: center;
        justify-content: flex-end;
        margin: 0;
        margin-left: auto;
    }

    .header-ul > li > a {
        color: var(--main-text-color);
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 200ms ease all;
        font-family: "Eczar", sans-serif;

        outline-offset: 0px;
        text-align: center;
        transform-style: preserve-3d;
        background-color: rgba(0, 0, 0, 0);
        border: 2px solid #fff;
        border-radius: 48px;
        outline: 1px #c49a4e;
        padding: 14px 24px;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.125em;
        transition: border-color .3s, transform .3s, color .3s;
        transform: translate(0);
        margin-left: 32px;

        &:hover {
            color: #c49a4e;
            border-color: #ddcead;
            transform: scale3d(.96, .96, 1.01);
        }
    }

    .header-ul > li > a.router-link-active {
        color: #88e1ff;
    }

    .header-ul > li {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .address {
        max-width: 170px;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-left: 32px;
    }

    .link-icon {
        width: 20px;
        height: 20px;
        display: inline-flex;
        background: url("/img/icons/link.png") center center no-repeat;
        background-size: contain;
    }

    .header-wrapper {
        position: relative;
        border-bottom: 1px solid #17353f;
        justify-content: space-between;
        align-items: center;
        padding-top: 32px;
        padding-bottom: 32px;
        display: flex;
        width: 100%;
    }

    .header-logo-link {
        width: 100%;
        max-width: 283px;
        flex: 1;
        margin-right: 15px;
        padding-left: 0;
        transition: opacity .3s;

        &:hover {
        opacity: .5;
        }
    }

    .w-nav-brand {
        float: left;
        color: #333;
        text-decoration: none;
        position: relative;
    }

    .header-logo.pd-logo {
        width: auto;
        height: 42px;
    }

    @media screen and (max-width: 1200px) {

        .mobile-header {
            display: block;
        }

        .address {
            text-align: center;
        }

        .dropdown {

            .dropdown-wrapper {
                border: none;
            }

            .dropdown-item {
                text-align: center;
                display: flex;
                justify-content: center;
            }
        }

    }

    .login-wrapper {
        margin-left: 0;
    }

    @media screen and (max-width: 991px) {
        .header-links {
            display: none;
        }

        .login-wrapper {
            margin-left: auto;
        }
    }

    li.mobile-links {
        display: none;
        margin: 4px 0;
    }

    li.mobile-links.first {
        margin-top: 12px;
        color: var(--main-text-color) !important;
    }

    @media screen and (max-width: 600px) {
        .login-wrapper {
            display: none;
        }
    }

    @media screen and (max-width: 479px) {
        li.mobile-links {
            display: flex;
            font-size: 14px;
            font-family: Eczar, sans-serif;
            font-weight: 500;
            line-height: 100%;

            a {
                border: none;
                color: var(--second-text-color);

                &:hover {
                    color: var(--main-text-color);
                }
            }

            a.log-out {
                display: flex;
                align-items: center;
                color: #ffbec2;
                &:hover {
                    color: var(--canceled-color);
                }
            }

            .flex:hover {
                color: var(--main-text-color);
            }
        }
        .header-logo.pd-logo {
            height: 32px;
        }

        .header-wrapper {
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }


    .w-inline-block {
        max-width: 100%;
        display: inline-block;
    }

    .logged-in-wrapper {
        color: rgba(255, 255, 255, .5);
        transform-style: preserve-3d;
        background-image: linear-gradient(rgba(0, 0, 0, .8), rgba(59, 114, 133, .3));
        border: 2px solid #133442;
        border-radius: 48px;
        align-items: center;
        margin-left: 24px;
        padding: 12px 24px;
        font-family: Eczar, sans-serif;
        font-size: 16px;
        font-weight: 500;
        line-height: 100%;
        text-decoration: none;
        transition: background-position .3s, background-color .3s, transform .3s, border .3s, color .3s;
        display: flex;
        transform: translate(0);
        align-items: center;
        cursor: pointer;

        &:hover {
            color: var(--main-text-color);
            background-image: radial-gradient(circle farthest-side at 50% 160%, rgba(140, 253, 159, .4), rgba(0, 0, 0, 0)), linear-gradient(rgba(0, 0, 0, .8), rgba(59, 114, 133, .3));
            border-color: #3b7285;
            transform: scale3d(.96, .96, 1.01);
        }

        @media screen and (max-width: 767px) {
            margin-left: 0;
            padding-left: 20px;
            padding-right: 20px;
            font-size: 14px;
        }
    }

    .login-menu-wrapper {
        position: relative;
    }

    .login-menu-dropdown {
        opacity: 0;
        position: absolute;
        top: auto;
        bottom: 0%;
        left: 0%;
        right: 0%;
        transition: 600ms ease all;
        visibility: hidden;
    }

    .card.user-popup {
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
        background-color: rgba(2, 27, 38, .8);
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        margin-top: 0;
        margin-left: auto;
        margin-right: auto;
        padding-top: 8px;
        padding-bottom: 8px;
        font-family: Eczar, sans-serif;
        font-size: 16px;
        font-weight: 500;
        line-height: 100%;
        display: flex;
        position: absolute;
        top: 1px;
        right: 0;
        overflow: hidden;
        box-shadow: 0 0 11px 5px rgba(0, 0, 0, .4);
        z-index: 1;
        min-width: 190px;
    }

    .login-menu-dropdown:hover,
    .logged-in-wrapper:hover ~ .login-menu-dropdown {
        opacity: 1;
        visibility: visible;
    }

    .card.user-popup {
        font-family: Eczar, sans-serif;
        font-size: 16px;
        font-weight: 500;
        line-height: 100%;
        @media screen and (max-width: 767px) {
            font-size: 14px;
        }
    }

    .menu-icon {
        margin-right: 8px;
    }

    .link-icon,
    .line-square-icon.menu-icon {
        margin-right: 8px;
        font-size: 20px;
    }

    .balance:hover {
        color: var(--main-text-color);
    }


    .line-square-icon.button-arrow.logged-in {
        color: #fff;
        font-size: 20px;
        line-height: 100%;
        padding-left: 12px;
    }

    .address-link {
        color: var(--second-text-color);
        &:hover {
            color: var(--main-text-color);
        }
    }

    .flex {
        display: flex;
        align-items: center;
    }

    .line-square-icon.menu-icon.wallet-icon {
        color: #def2e6;
    }

</style>

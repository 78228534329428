<template>
    <div class="app-wrapper">
    <!-- <NuxtErrorBoundary @error="someErrorLogger"> -->
        <AppHeader />

        <div class="page">
            <div class="main-body-container">
                <slot />
            </div>
            <div class="mobile-footer">
                <AppFooter />
            </div>
            <div class="footer">
                <AppFooter />
            </div>
        </div>

        <el-dialog v-model="showPopupValue" title="Error" @closed="close" :append-to-body="true">
            <div class="error-wrapper">{{ error }}</div>
            <div class="btns">
                <button @click="close" class="btn-primary">close</button>
            </div>
        </el-dialog>

        <Notifications :key="notificationUpdateVersion" />
    </div>
</template>

<script lang="ts">
    import { defineComponent } from "vue";
    import AppFooter from "~/components/AppFooter.vue";
    import AppHeader from "~/components/AppHeader.vue";
    import { ElDialog } from "element-plus";
    import { useMainStore } from "~/stores/mainStore";
    import { mapState, mapActions } from "pinia";
    import Notifications from "~/components/Notifications.vue";
    import { updateProcessingTransactions } from "~/composables/utils/utils";

    export default defineComponent({
        components: {
            AppHeader,
            AppFooter,
            "el-dialog": ElDialog,
            Notifications
        },
        data(): {
            showPopup: boolean;
            errorMessage: string;
        } {
            return {
                showPopup: false,
                errorMessage: "",
            };
        },
        computed: {
            ...mapState(useMainStore, ["error", "notificationUpdateVersion"]),
            showPopupValue(): boolean {
                return !!this.error;
            },
        },
        mounted() {
            // dark mode for eleme components
            if (document) {
                document.getElementsByTagName("html")[0].classList.add("dark");
            }
            updateProcessingTransactions(); // show all processing transaction
            this.updateVersion();
        },
        methods: {
            ...mapActions(useMainStore, ["setError", "updateVersion"]),
            close() {
                this.setError("");
            },
        }
    });
</script>

<style scoped lang="scss">


    #content {
        position:absolute;
        right:0;
        left:0
    }

    .mobile-footer {
        display: none;
    }


    @media screen and (max-width: 1200px) {
        .mobile-footer {
            display: block;
        }

        .footer {
            display: none;
        }
    }

    .page {
        background-color: var(--blue-1);
        background-image: linear-gradient(rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, .1));
    }

    .app-wrapper {
        display: flex;
        flex-direction: column;
        min-height: calc(100% - 1px);
    }

    .error-wrapper {
        margin-bottom: 12px;
    }

</style>

<style lang="scss">

    .el-dialog.login-popup {
        width: 280px;
    }

    .el-dialog.video-popup {
        width: 100%;
        max-width: 720px;

        .el-dialog__body {
            max-width: 700px;
        }
    }

    .el-dialog.buy-popup {
        max-width: 350px;
    }

    .el-dialog.is-fullscreen {
        width: 100%;
        max-width: 100%;
    }

    .el-dialog {
        border-radius: 12px;
        // border: 2px solid var(--main-blue);
        backdrop-filter: blur(5px);
        background-color: rgba(2, 27, 38, .8);
        overflow: hidden;
        max-width: 560px;
        box-shadow: 0 0 11px 5px rgba(0, 0, 0, .4);
        border: 1px solid #17353f;

        &__header {
            text-align: center;
            margin: 0;
            padding: 0 0 0 0;
            height: 56px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: transparent;

            font-family: Grenze, sans-serif;
            font-size: 24px;
            font-weight: 800;
            line-height: 1.273em;
        }

        .el-dialog__title {
            font-size: 24px;
        }

        &__body {
            max-width: 415px;
            margin: auto;
            padding-bottom: 20px;
            padding-top: 12px;
        }

        &__close,
        .el-icon svg {
            height: 30px;
            width: 30px;
        }
    }

    .popup-canceled {
        border: 2px solid var(--canceled-color);
    }

    .popup-confirm {
        border: 2px solid var(--confirm-color);
    }

    .el-button {
        display: flex;
        padding: 0 38px;
        justify-content: center;
        align-items: center;
        gap: 12px;
        border-radius: 86px;
        background: var(--main-blue);
        text-align: center;
        font-size: 24px;
        color: var(--main-text-color);
        border: 1px solid transparent;
        height: 47px;

        &:active,
        &:focus,
        &:hover {
            background: transparent;
            border: 1px solid #939393;
        }
    }

    .table .el-table {
        --el-table-border-color: none;
        font-size: 24px;
        color: rgba(255, 255, 255, .55);
        font-family: Grenze, sans-serif;
    }

    .el-table tr:nth-child(even) {
        background-color: rgba(0, 0, 0, .3);
    }

    .table .el-table th.el-table__cell {
        background: var(--dark-2);
        background-image: linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .5));
        margin-bottom: 0;
        padding-top: 16px;
        padding-bottom: 16px;
        background-color: transparent;
        color: #fff;
        font-family: Grenze, sans-serif;
        font-size: 24px;
        font-weight: 400;

        @media screen and (max-width: 991px) {
            font-size: 16px;
        }

        @media screen and (max-width: 767px){
            font-size: 14px;
        }

        @media screen and (max-width: 479px) {
            font-size: 12px;
        }
    }

    .el-table .el-table__cell {
        @media screen and (max-width: 991px) {
            font-size: 16px;
        }

        @media screen and (max-width: 767px){
            font-size: 14px;
        }

        @media screen and (max-width: 479px) {
            font-size: 12px;
        }
    }

    .el-table .cell {
        word-break: normal;
    }

    .el-table th.el-table__cell:first-child {
        border-radius: 16px 0 0 0;
    }

    .el-table th.el-table__cell:last-child {
        border-radius: 0 16px 0 0;
    }

    .el-icon.el-dialog__close svg {
        display: none;
    }

    .el-icon.el-dialog__close {
        background-image: url("/img/icons/bone_close.svg");
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50%;

        &:hover {
            opacity: .6;
        }
    }

    .btns {
        display: flex;
        justify-content: center;
    }

    .el-button + .el-button {
        margin-left: 0 !important;
    }

    .el-checkbox__label {
        color: var(--second-text-color);
        text-align: left;
        font-family: Grenze, sans-serif;
        white-space: initial;
        line-height: 1.333em;
        font-size: 16px !important;

        @media screen and (max-width: 767px) {
            font-size: 14px !important;
        }
    }

    .el-checkbox {
        display: flex;
        align-items: flex-start;
        height: auto !important;
    }

    .el-checkbox__input {
        margin-top: 4px;
    }

    .el-checkbox__input.is-checked + .el-checkbox__label {
        color: var(--main-text-color);
    }

</style>